<template>
  <v-layout
    row
    px-5
  >
    <v-flex
      xs12
      md10
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
          my-4
        >
          <v-flex xs4>
            <v-card
              flat
              color="transparent"
              style="width: 210px;"
              router
              :to="{name: 'redirect'}"
            >
              <v-layout
                align-center
                justify-start
                row
              >
                <v-icon
                  class="mr-2"
                  small
                  color="#00b2c0"
                >
                  far fa-arrow-left
                </v-icon>
                <v-flex>
                  <div
                    class="body-1"
                    style="color: #00b2c0"
                  >
                    Regresar a empleos
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-flex>
        <v-flex
          xs12
          mb-4
        >
          <div
            class="headline"
            style="font-weight: bold"
          >
            Bienvenido a Soporte y Ayuda Mandomedio
          </div>
        </v-flex>
        <v-flex
          xs12
          mb-5
        >
          <div class="body-1">
            ¿Buscando respuestas? Encuentra toda la información que
            necesitas para utilizar nuestra plataforma aquí. Puedes comenzar
            ingresando tus dudas en el buscador o en las siguientes categorías
          </div>
        </v-flex>
        <v-flex
          v-for="category in groupedFAQs"
          :key="category.name"
          xs12
          md6
        >
          <v-card
            :class="`mb-5 elevation-20 ${$vuetify.breakpoint.mdAndUp ? 'mx-5' : ''}`"
            style="border-radius: 10px"
          >
            <v-card-title
              style="background: #00b2c0"
              class="white--text"
            >
              <v-layout
                align-center
                justify-start
                row
              >
                <v-flex mr-1>
                  <v-icon color="white">
                    {{ category.icon }}
                  </v-icon>
                </v-flex>
                <v-flex
                  xs12
                  md11
                >
                  <div class="title">
                    {{ category.name }}
                  </div>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-actions>
              <v-layout column>
                <v-card
                  v-for="(question, index) in category.questions"
                  :key="question.id"
                  router
                  :to="{name: 'faq:detail', params: {faqid: question.id}}"
                  flat
                >
                  <template v-if="index !== 0">
                    <v-divider />
                  </template>
                  <v-layout
                    align-center
                    justify-space-around
                    row
                    ma-3
                  >
                    <v-flex>
                      <div class="body-1">
                        {{ question.question }}
                      </div>
                    </v-flex>
                    <v-icon
                      small
                    >
                      far fa-arrow-right
                    </v-icon>
                  </v-layout>
                </v-card>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from 'axios';

export default {
  name: 'FAQList',
  data() {
    return {
      faqs: [],
    };
  },
  computed: {
    groupedFAQs() {
      return this.categories.map(category => ({
        name: category.name,
        icon: category.icon,
        position: category.position,
        questions: this.faqs.filter(faq => faq.category_name === category.name),
      })).sort((cat1, cat2) => cat1.position - cat2.position);
    },
    categories() {
      const categoriesWithoutReps = [];
      this.faqs.forEach(faq => {
        if (!categoriesWithoutReps.some(element => element.name === faq.category_name)) {
          console.log('aaaaa');
          categoriesWithoutReps.push({
            name: faq.category_name,
            icon: faq.category_icon,
            position: faq.category_position,
          });
        }
      });
      return categoriesWithoutReps;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.loadFAQs();
    });
  },

  methods: {
    loadFAQs() {
      axios.get(this.$store.state.endpoints.faq.list)
        .then(response => {
          this.faqs = response.data.sort((faq1, faq2) => faq1.position - faq2.position);
        });
    },
  },
};
</script>
